export const passwordRuleLabels = {
  minMaxLength: '8-24 символа',
  caseSymbols: 'Должен включать латинские буквы верхнего и нижнего регистра',
  specialSymbols:
    'Должен включать хотя бы одну цифру и один из следующих символов: . , : ; ? ! * + % - < > @ [ ] {} / _ $ #'
}

export const loginRuleLabels = {
  minMaxLength: '6-20 символов',
  startEnd: 'Должен начинаться с буквы, не может заканчиваться точкой',
  specialSymbol: 'Может включать цифры и следующие символы: - _ .',
  letters: 'Должен включать латинские буквы',
}
