export default (val: string) => {
  const errors = {
    specialSymbols: false,
    caseSymbols: false,
    minMaxLength: false,
  }
  // - Должен включать хотя бы одну цифру и спец.символ
  if (/^(?=.*[0-9])(?=.*[.,:;?!*+%\-<>@[\]/{}_$#]).+$/g.test(val)) {
    errors.specialSymbols = true
  }
  // - Должен включать латинские буквы верхнего и нижнего регистра
  if (/(?=.*[a-z])(?=.*[A-Z])(?!(.*[А-Яа-я]))/g.test(val)) {
    errors.caseSymbols = true
  }
  // 8 - 24 символа
  if (val.length >= 8 && val.length <= 24) {
    errors.minMaxLength = true
  }

  return errors
}
