import { defineStore } from 'pinia';
import { RootConfirmationState } from "@/types/store";

export const useConfirmationStore = defineStore('confirmation', {
  state: (): RootConfirmationState => ({
    isConfirmActive: false,
    loading: false,
    title: '',
    text: '',
    buttonText: '',
    type: 'default',
    resolve: () => {},
    reject: () => {},
  }),
  actions: {
    CONFIRM(title: string, buttonText: string, text?: string, type: 'default' | 'danger' = 'default') {
      this.title = title;
      this.text = text || '';
      this.buttonText = buttonText;
      this.type = type;
      this.isConfirmActive = true;

      return new Promise<void>((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject;
      })
    },
    SUCCESS_CONFIRM() {
      this.resolve()
    },
    CANCEL_CONFIRM() {
      this.isConfirmActive = false
      this.reject()
    },
    STOP_LOADING() {
      this.loading = false
      this.isConfirmActive = false
    },
    START_LOADING() {
      this.loading = true
    },
  }
})
