export default {
  play: 'Воспроизвести',
  pause: 'Пауза',
  playback: 'Воспроизведение',
  scrubber: 'Прогресс',
  scrubberLabel: '{currentTime} из {duration}',
  played: 'Играл',
  duration: 'Продолжительность',
  buffered: 'В буфере',
  close: 'Закрыть',
  currentTime: 'Текущее время',
  live: 'LIVE',
  volume: 'Громкость',
  mute: 'Отключить звук',
  unmute: 'Включить звук',
  audio: 'Аудио',
  default: 'По умолчанию',
  captions: 'Титры',
  subtitlesOrCc: 'Субтитры/CC',
  enableCaptions: 'Включить суб-/титры',
  disableCaptions: 'Выключить суб-/титры',
  auto: 'Авто',
  fullscreen: 'Полный экран',
  enterFullscreen: 'Развернуть на весь экран',
  exitFullscreen: 'Выйти из полноэкранного режима',
  settings: 'Настройки',
  seekForward: 'Промотать вперёд',
  seekBackward: 'Перемотать назад',
  seekTotal: 'Перемотать в целом',
  normal: 'Нормально',
  none: 'Нет',
  playbackRate: 'Скорость воспроизведения',
  playbackQuality: 'Качество воспроизведения',
  loop: 'Цикл',
  disabled: 'Отключено',
  off: 'Off',
  enabled: 'Разрешено',
  pip: 'Картинка-в-картинке',
  enterPiP: 'Войти в режим Картинка-в-картинке',
  exitPiP: 'Выйти из режима Картинка-в-картинке',
}
