import dayjs from "dayjs";

export default (date: string | null): {text: string, status: 'offline' | 'online' | null} => {
  if (!date) {
    return { text: 'Неактивная УЗ', status: null }
  }
  const now = dayjs()
  const activityDate = dayjs(date)
  const diff = now.diff(activityDate, 'm')
  if (diff < 5) {
    return { text: 'В сети', status: 'online' }
  }
  return { text: activityDate.format('DD.MM.YYYY HH:mm'), status: 'offline' }
}
