import { defineStore } from 'pinia'
import api from '@/api'
import { RootAuthState } from "@/types/store";
import { useProfileStore } from '@/store/profile';

export const useAuthStore = defineStore('auth', {
  state: (): RootAuthState => ({
    isAuth: localStorage.getItem('isAuth')
  }),

  actions: {
   ON_LOGIN(login: string, password: string) {
    const profileStore = useProfileStore();

    return api.auth.login(login, password)
      .then((res) => {
        this.isAuth = 'true'
        localStorage.setItem('isAuth', 'true')
        profileStore.SET_USER(res.data.data.user)
      })
    },
    ON_LOGOUT() {
      return api.auth.logout()
        .then(() => {
          localStorage.clear()
          location.reload()
        })
    }
  },

  getters: {
    IS_AUTH: (state) => state.isAuth === 'true'
  }
})
