import instance from "@/api/http";
import { AuthResponse } from "@/types/api";

export default {
  login(login: string, password: string) {
    const data = { login, password }
    return instance.post<AuthResponse>('auth', data)
  },

  logout() {
    return instance.patch('logout')
  }
}
