<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 21V14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 10V3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 21V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 8V3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 21V16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 12V3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 14H7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 8H15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 16H23" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
