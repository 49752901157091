<template>
  <Player muted controls :translations="translations" @vmError="$emit('errorHandler', $event)" autoplay :autopause="false">
    <Hls>
      <source :data-src="link" type="application/x-mpegURL" />
    </Hls>
  </Player>
</template>

<script>
import {
  Player, Hls, ControlGroup, ControlSpacer, Control, Icon, Tooltip, ScrubberControl, Ui,
  Controls, LiveIndicator, Time as PlayerTime, CurrentTime,
  PlaybackControl, VolumeControl, PipControl, FullscreenControl, DblClickFullscreen, ClickToPlay, LoadingScreen
} from '@vime/vue-next'
import ru from '@/components/media-player/translations'

export default {
  name: 'PlayerWrap',
  components: {
    Player, Hls, ControlGroup, ControlSpacer, Control, Icon, Tooltip,
    ScrubberControl, Ui, Controls, LiveIndicator, PlayerTime, CurrentTime,
    PlaybackControl, VolumeControl, PipControl, FullscreenControl, DblClickFullscreen,
    ClickToPlay, LoadingScreen
  },
  emits: ['errorHandler'],
  data: (vm) => ({
    archive: '',
    key: 0,
    streamLoading: false,
    translations: { ru },
    hlsConfig: {
      manifestLoadingMaxRetry: 10,
      liveDurationInfinity: vm.isLive,
    },
  }),
  props: {
    link: String
  },
  computed: {
    isLive() { return !this.archive }
  },
  methods: {
    makeTimeline() {
      console.log(this.$el);
      const scrubber = this.$el.querySelector('media-scrubber')
      const video = this.$el.querySelector('video')

      scrubber.media = video
      console.log(scrubber)
    },
    reload() {
      this.$emit('reloadPlaylist')
      this.onError()
    },
    takeScreenshot() {
      const player = this.$el.getElementsByTagName('video')[0]
      const canvas = document.createElement('canvas')

      canvas.width = player.videoWidth
      canvas.height = player.videoHeight
      canvas.getContext('2d').drawImage(player, 0, 0, canvas.width, canvas.height)
      const link = document.createElement('a')
      link.href = canvas.toDataURL()
      link.click()
      link.remove()
    }
  },
}
</script>

<style>
vm-ui {
  --vm-ui-z-index: 10000
}
</style>
