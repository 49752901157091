export default (msg: string) => {
  switch (msg) {
    case 'Internal Server Error':
      return 'Внутренняя ошибка сервера'
    case 'sentinel error':
      return 'Внутренняя ошибка сервера'
    case 'Forbidden':
      return 'Нет прав доступа'
    case 'login is already exists':
      return 'Логин уже существует'
    case 'title is already exists':
      return 'Название уже существует'
    case 'image already has decision':
      return 'Изображение уже имеет решение'
    case 'no decision':
      return 'Решение уже отменено'
    case 'task is stopped':
      return 'Задание остановлено'
    case 'task is not active':
      return 'Задание не активно'
    case 'task is already active':
      return 'Задание уже активно'
    case 'start time greater than end time':
      return 'Время начала больше времени окончания'
    case 'task is already paused':
      return 'Задание уже приостановлено'
    case 'task is already started':
      return 'Задание уже запущено'
    case 'task is already stopped':
      return 'Задание уже остановлено'
    case 'user not found':
      return 'Пользователь не найден'
    case 'event not found':
      return 'Событие не найдено'
    case 'group not found':
      return 'Группа не найдена'
    case 'task not found':
      return 'Задание не найдено'
    case 'image not found':
      return 'Изображение не найдено'
    case 'source not found':
      return 'Камера не найдена'
    case 'binding error':
      return 'Ошибка привязки'
    case 'validation error':
      return 'Ошибка валидации'
    case 'incorrect range':
      return 'Неправильный диапазон'
    case 'eks error':
      return 'Ошибка ЕКС'
    case 'skit error':
      return 'Ошибка СКИТ'
    case 'conversion interface error':
      return 'Ошибка преобразования'
    case 'exception already exists':
      return 'Исключение уже существует'
    default:
      return msg
  }
}
