export default (params: {[key: string]: unknown}, currentItem: {[key: string]: unknown} | null) => {
  if (!currentItem) {
    return params
  }
  const preparingParams: {[key: string]: unknown} = {}

  Object.keys(params).forEach((i) => {
    if (params[i] !== currentItem[i]) {
      preparingParams[i] = params[i]
    }
  })
  return preparingParams
}
