import { defineStore } from "pinia";
import { RootTasksState } from "@/types/store";
import { TaskCreationData, TaskUpdateData } from '@/types/tasks';
import api from '@/api'
import { TasksRequest } from "@/types/api";
import router from "@/router";
import addQuery from "@/helpers/addQuery";
import { useTasksFiltersStore } from "@/store/tasks/tasksFilters";

export const useTasksStore = defineStore('tasks', {
  state: (): RootTasksState => ({
    searchValue: '',
    summaryFilter: '',
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    allItemsCount: 0,
    activeItemsCount: 0,
    notActiveItemsCount: 0,
    pausedItemsCount: 0,
    stoppedItemsCount: 0,
    isLoading: false,
    tasks: [],
    selectedTasks: [],
    currentTaskId: '',
  }),

  actions: {
    GET_TASKS() {
      const tasksFiltersStore = useTasksFiltersStore()

      const payload: TasksRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        title: this.searchValue,
        label: tasksFiltersStore.labels,
        daysWeek: tasksFiltersStore.daysWeek
      }

      if (tasksFiltersStore.sortBy) {
        payload.sortBy = tasksFiltersStore.sortBy
        payload.sortOrder = tasksFiltersStore.sortOrder
      }

      if (this.summaryFilter) {
        payload.status = this.summaryFilter
      }

      this.isLoading = true
      return api.tasks.getTasks(payload)
        .then((res) => {
          const { tasks, count } = res.data.data
          const { paused, in_progress, stopped, not_active, all } = count
          this.tasks = tasks
          this.totalItems = all
          this.allItemsCount = in_progress + not_active + paused + stopped
          this.activeItemsCount = in_progress
          this.notActiveItemsCount = not_active
          this.pausedItemsCount = paused
          this.stoppedItemsCount = stopped
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    CREATE_TASK(data: TaskCreationData) {
      return api.tasks.createTask(data)
    },

    START_TASK(ids: Array<string>) {
      return api.tasks.startTask(ids)
    },

    STOP_TASK(ids: Array<string>) {
      return api.tasks.stopTask(ids)
    },

    PAUSE_TASK(ids: Array<string>) {
      return api.tasks.pauseTask(ids)
    },

    UPDATE_TASK(id: string, data: TaskUpdateData) {
      return api.tasks.updateTask(id, data)
    },

    DELETE_TASKS(ids: Array<string>) {
      return api.tasks.deleteTasks(ids)
    },

    DELETE_TASKS_FROM_SELECTED(tasksIds: Array<string>) {
      tasksIds.forEach((taskId) => {
        this.selectedTasks = this.selectedTasks.filter((i) => i !== taskId)
      })
    },

    RESET_SELECTED_TASKS() {
      this.selectedTasks = []
    },

    INIT_PAGINATION() {
      this.currentPage = Number(router.currentRoute.value.query.page) || 1
      return addQuery('page', String(this.currentPage))
    }
  },

  getters: {
  }
})
