import { DecisionEnum } from "@/types/events";

export default (decision: string) => {
  switch (decision) {
    case DecisionEnum.accepted:
      return 'Подтверждено'
    case DecisionEnum.other_label:
      return 'Подтверждено'
    case DecisionEnum.rejected:
      return 'Не подтверждено'
    case DecisionEnum.correction:
      return 'Уточнение'
    case DecisionEnum.without_decision:
      return 'Ожидает подтверждения'
    default:
      return ''
  }
}
