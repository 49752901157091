import { Nullable } from "@/types/helpers";
import { cameraStatuses } from "@/consts/sourcesConsts";

export type CamerasStatusCode = keyof typeof cameraStatuses

export type Group = {
  id: string
  title: string
  description: string
  createdAt: Nullable<string>
  deletedAt: Nullable<string>
  updatedAt: Nullable<string>
}

export type Camera = {
  id: string
  login: string
  title: string
  description: Nullable<string>
  districts: string
  address: string
  groups: Array<Group>
  rtspUrl: string
  cameraType: string
  status: CamerasStatusCode
  createdAt: string
  updatedAt: string
  deletedAt: Nullable<string>
}

export enum CamerasFiltersEnum {
  work = '0',
  snapshot_problems = '1',
  disabled = '2',
  clarification = '3',
  deleted = '4',
  plan = '5',
  unavailable = '6',
}
