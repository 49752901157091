import instance from "@/api/http";
import type { ExceptionsRequest, ExceptionsResponse } from "@/types/api";
import {ExceptionCreationData, ExceptionUpdateData} from "@/types/exceptions";

export default {
  getExceptions(params: ExceptionsRequest) {
    return instance.get<ExceptionsResponse>(`exceptions`, { params })
  },
  createException(data: ExceptionCreationData) {
    return instance.post(`exceptions`, data)
  },
  updateException(id: string, data: ExceptionUpdateData) {
    return instance.patch(`exceptions/${id}`, data)
  },
  deleteExceptions(exceptionIds: Array<string>) {
    const params = { exceptionId: exceptionIds }
    return instance.delete(`exceptions`, { params })
  }
}
