import { defineStore } from "pinia";
import { RootEventsFiltersState } from '@/types/store';
import { OrderEnum } from "@/types/main";
import { EventsTabEnum } from "@/types/events";

export const useEventsFiltersStore = defineStore('eventsFilters', {
  state: (): RootEventsFiltersState => ({
    tab: EventsTabEnum.unprocessed,
    decisions: [],
    labels: [],
    districts: [],
    groupsIds: [],
    camerasIds: [],
    addresses: [],
    tasks: [],
    decisionPeriod: ['', ''],
    createdPeriod: ['', ''],
    sortBy: '',
    sortOrder: OrderEnum.desc
  }),

  actions: {
    SET_SORT_BY(sort: string) {
      this.sortBy = sort
    },
    TOGGLE_ORDER() {
      if (this.sortOrder === OrderEnum.desc) {
        this.sortOrder = OrderEnum.asc
      } else {
        this.sortOrder = OrderEnum.desc
      }
    }
  }
})
