import instance from "@/api/http";
import { IterationsRequest, IterationsResponse } from "@/types/api";

export default {
  getIterations(data: IterationsRequest) {
    const params = {
      limit: data.limit,
      offset: data.offset,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
      status: data.status,
    }
    return instance.get<IterationsResponse>(`iterations/${data.taskId}`, { params })
  },
}
