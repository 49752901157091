import { defineStore } from "pinia";
import { RootUsersFiltersState } from '@/types/store';
import { OrderEnum } from "@/types/main";

export const useUsersFiltersStore = defineStore('usersFilters', {
  state: (): RootUsersFiltersState => ({
    sortBy: '',
    sortOrder: OrderEnum.desc
  }),

  actions: {
    SET_SORT_BY(sort: string) {
      this.sortBy = sort
    },
    TOGGLE_ORDER() {
      if (this.sortOrder === OrderEnum.desc) {
        this.sortOrder = OrderEnum.asc
      } else {
        this.sortOrder = OrderEnum.desc
      }
    }
  }
})
