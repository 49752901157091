import { DaysWeekType } from "@/types/main";
import { User } from "@/types/users";
import { Nullable } from "@/types/helpers";
import { tasksCategories } from "@/consts/tasksConsts";

export type TaskCategory = keyof typeof tasksCategories

export enum TaskStatus {
  inProgress = 'in_progress',
  paused = 'paused',
  stopped = 'stopped',
  notActive = 'not_active'
}

export type Task = {
  id: string,
  title: string,
  labels: Array<TaskCategory>,
  startTime: string,
  actionTime: string,
  daysWeek: DaysWeekType,
  status: TaskStatus,
  sourcesGroups: null,
  sources: Array<string>,
  shift?: number,
  zoom?: number,
  createdBy: Nullable<User>,
  createdAt: string,
  updatedAt: string,
  deletedAt: Nullable<string>
  minute: number
}

export enum IterationType {
  inProgress = 'in_progress',
  finished = 'finished'
}

export type Iteration = {
  id: string
  task: Task
  status: IterationType
  downloaded: number
  processed: number
  expected: number
  notDownloaded: number
  events: number
  createdAt: string
  updatedAt: string
  deletedAt: Nullable<string>
  progress: number
}

export type TaskCreationData = {
  title: string
  labels?: Array<string>
  startTime?: string
  sources?: Array<string>
  sourcesGroups?: Array<string>
  districts?: Array<string>
  minute?: number
  hour?: number
  daysWeek?: Array<number>
  zoom?: number
  shift?: number
}

export type TaskUpdateData = {
  actionTime?: string
  startTime?: string
  daysWeek?: Array<number>
  addSources?: Array<string>
  removeSources?: Array<string>
  addSourcesGroups?: Array<string>
  removeSourcesGroups?: Array<string>
  addDistricts?: Array<string>
  removeDistricts?: Array<string>
}

export enum confirmDialog {
  pause,
  delete,
  restore,
  stop
}
