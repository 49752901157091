import auth from '@/api/modules/auth'
import users from "@/api/modules/users";
import tasks from "@/api/modules/tasks";
import iterations from "@/api/modules/iterations";
import images from "@/api/modules/images";
import sources from "@/api/modules/sources";
import statistics from "@/api/modules/statistics";
import exceptions from "@/api/modules/exceptions";

export default {
  auth,
  users,
  tasks,
  iterations,
  images,
  sources,
  statistics,
  exceptions
}
