import { UserRole } from "@/types/users";

export default (role: UserRole) => {
  switch (role) {
    case UserRole.admin:
      return 'Администратор'
    case UserRole.operator:
      return 'Оператор'
    default:
      return ''
  }
}
