import instance from "@/api/http";
import { DecisionImageData, ImagesRequest, ImagesResponse } from "@/types/api";

export default {
  getImages(data: ImagesRequest) {
    const params = {
      limit: data.limit,
      offset: data.offset,
      iterationId: data.iterationsIds,
      sourceId: data.sourceId,
      label: data.label,
      district: data.district,
      searchBy: data.searchBy || null,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
      decision: data?.decision,
      groupId: data?.groupId,
      sourceAddress: data?.sourceAddress,
      taskTitle: data?.taskTitle,
      fromDecisionAt: data?.fromDecisionAt || null,
      toDecisionAt: data?.toDecisionAt || null,
      fromCreatedAt: data?.fromCreatedAt || null,
      toCreatedAt: data?.toCreatedAt || null
    }
    return instance.get<ImagesResponse>(`images`, { params })
  },
  deleteImages(imagesIds: Array<string>) {
    const params = { imageId: imagesIds }
    return instance.delete(`images`, { params })
  },
  decideOnImage(id: string, data: DecisionImageData) {
    return instance.post(`images/${id}`, data)
  },
  cancelDecision(id: string) {
    return instance.post(`images/cancel/${id}`)
  }
}
