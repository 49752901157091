import { defineStore } from "pinia";
import { RootEventsState } from "@/types/store";
import api from "@/api";
import { ImagesRequest } from "@/types/api";
import router from "@/router";
import addQuery from "@/helpers/addQuery";
import deleteQuery from "@/helpers/deleteQuery";
import { useEventsFiltersStore } from '@/store/events/eventsFilters';
import { DecisionEnum, EventsTabEnum } from "@/types/events";

export const useEventsStore = defineStore('events', {
  state: (): RootEventsState => ({
    searchValue: '',
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    selectedImages: [],
    images: [],
    isLoading: false,
    currentImage: null,
    currentEventIndex: 0,
    dialogActive: false
  }),

  actions: {
    GET_IMAGES(iterationId?: string) {
      const eventsFiltersStore = useEventsFiltersStore()

      const payload: ImagesRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        searchBy: this.searchValue,
        label: eventsFiltersStore.labels,
        district: eventsFiltersStore.districts,
        groupId: eventsFiltersStore.groupsIds,
        sourceId: eventsFiltersStore.camerasIds,
        sourceAddress: eventsFiltersStore.addresses,
        taskTitle: eventsFiltersStore.tasks,
        fromDecisionAt: eventsFiltersStore.decisionPeriod[0],
        toDecisionAt: eventsFiltersStore.decisionPeriod[1],
        fromCreatedAt: eventsFiltersStore.createdPeriod[0],
        toCreatedAt: eventsFiltersStore.createdPeriod[1],
      }
      if (eventsFiltersStore.tab === EventsTabEnum.processed) {
        payload.decision = [DecisionEnum.accepted, DecisionEnum.rejected, DecisionEnum.correction]
      } else if (eventsFiltersStore.tab === EventsTabEnum.unprocessed) {
        payload.decision = [DecisionEnum.without_decision]
      }
      if (eventsFiltersStore.sortBy) {
        payload.sortBy = eventsFiltersStore.sortBy
        payload.sortOrder = eventsFiltersStore.sortOrder
      }
      if (iterationId) {
        payload.iterationsIds = [iterationId]
      }

      this.isLoading = true
      return api.images.getImages(payload)
        .then((res) => {
          this.images = res.data.data.images
          switch (eventsFiltersStore.tab) {
            case EventsTabEnum.all:
              this.totalItems = res.data.data.count?.all || 0
              break
            case EventsTabEnum.processed:
              this.totalItems = res.data.data.count?.handled || 0
              break
            case EventsTabEnum.unprocessed:
              this.totalItems = res.data.data.count?.not_handled || 0
              break
            default:
              this.totalItems = res.data.data.count?.all || 0
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    DELETE_IMAGES(ids: Array<string>) {
      return api.images.deleteImages(ids)
    },

    DELETE_IMAGES_FROM_SELECTED(imagesIds: Array<string>) {
      imagesIds.forEach((imageId) => {
        this.selectedImages = this.selectedImages.filter((i) => i !== imageId)
      })
    },

    RESET_SELECTED_IMAGES() {
      this.selectedImages = []
    },

    OPEN_DIALOG(eventId: string) {
      this.images.forEach((value, index) => {
        if (eventId === value.id) {
          this.currentEventIndex = index
          this.currentImage = value
        }
      })
      addQuery('eventId', eventId)
      this.dialogActive = true
    },

    CLOSE_DIALOG() {
      deleteQuery('eventId')
      this.dialogActive = false
      this.currentImage = null
    },

    INIT_DIALOG() {
      const { eventId } = router.currentRoute.value.query
      if (typeof eventId === 'string') {
        this.OPEN_DIALOG(eventId)
      }
    },

    NEXT_EVENT() {
      if (!this.dialogActive) {
        return false
      }

      if (this.currentEventIndex < this.images.length - 1) {
        this.currentEventIndex++
        this.OPEN_DIALOG(this.images[this.currentEventIndex]?.id)
        return true
      }
      return false
    },

    PREV_EVENT() {
      if (!this.dialogActive) {
        return
      }
      if (this.currentEventIndex > 0) {
        this.currentEventIndex--
        this.OPEN_DIALOG(this.images[this.currentEventIndex]?.id)
      }
    },

    INIT_PAGINATION() {
      this.currentPage = Number(router.currentRoute.value.query.page) || 1
      return addQuery('page', String(this.currentPage))
    }
  }
})
