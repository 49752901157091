import type { TaskCategory } from "@/types/tasks";
import type { tasksCategories } from "@/consts/tasksConsts";

export default (obj: typeof tasksCategories) => {
  const keys = Object.keys(obj) as Array<TaskCategory>

  return keys.map((i) => {
    return {
      value: i,
      label: obj[i]
    }
  })
}
