import getCategoriesOptions from "@/helpers/getCategoriesOptions";

export const tasksCategories = {
  'spkv-dirt_speck': 'Пятна или грязь',
  'spkv-defocus': 'Расфокусировка',
  'spkv-destroyed_frame': 'Разрушенные кадры',
  'spkv-looking_at_wall': 'Камера направлена в стену',
  'spkv-monocrom_image': 'Монохромное изображение',
  'spkv-obstacle_in_tree': 'Помеха обзору в виде деревьев',
  'spkv-obstacle_in_view': 'Помеха обзору',
  'spkv-wrong_coloring': 'Нарушена цветопередача',
  scene_change_detection: 'Несоответствие эталонной сцене обзора',
}

export const categoriesOptions = getCategoriesOptions(tasksCategories)

export const weekdaysOptions = [
  { value: 1, label: 'Пн' },
  { value: 2, label: 'Вт' },
  { value: 3, label: 'Ср' },
  { value: 4, label: 'Чт' },
  { value: 5, label: 'Пт' },
  { value: 6, label: 'Сб' },
  { value: 7, label: 'Вс' },
]
