import instance from "@/api/http";
import { TaskResponse, TasksRequest, TasksResponse } from "@/types/api";
import { TaskCreationData, TaskUpdateData } from '@/types/tasks';

export default {
  getTask(id: string) {
    return instance.get<TaskResponse>(`tasks/${id}`)
  },
  getTasks(data: TasksRequest) {
    const params = {
      limit: data.limit,
      offset: data.offset,
      status: data.status,
      label: data.label,
      sortBy: data.sortBy,
      sortOrder: data.sortOrder,
      daysWeek: data.daysWeek,
      title: data.title || null
    }
    return instance.get<TasksResponse>('tasks', { params })
  },
  createTask(data: TaskCreationData) {
    return instance.post('tasks', data)
  },
  startTask(taskIds: Array<string>) {
    const data = { taskIds }
    return instance.post(`tasks/start`, data)
  },
  stopTask(taskIds: Array<string>) {
    const data = { taskIds }
    return instance.post(`tasks/stop`, data)
  },
  pauseTask(taskIds: Array<string>) {
    const data = { taskIds }
    return instance.post(`tasks/pause`, data)
  },
  updateTask(id: string, data: TaskUpdateData) {
    return instance.patch(`tasks/${id}`, data)
  },
  deleteTasks(taskIds: Array<string>) {
    const params = { taskId: taskIds }
    return instance.delete(`tasks`, { params })
  }
}
