import { defineStore } from "pinia";
import { RootExceptionsState } from "@/types/store";
import api from "@/api";
import { ExceptionCreationData, ExceptionUpdateData } from "@/types/exceptions";
import { useCameraStore } from "@/store/sources/camera";
import { type ExceptionsRequest } from "@/types/api";
import { useExceptionsFiltersStore } from "@/store/sources/exceptionsFilters";

export const useExceptionsStore = defineStore('exceptions', {
  state: (): RootExceptionsState => ({
    exceptions: [],
    selectedExceptions: [],
    isLoading: false
  }),
  actions: {
    GET_EXCEPTIONS() {
      const cameraStore = useCameraStore()
      const exceptionsFiltersStore = useExceptionsFiltersStore()

      const payload: ExceptionsRequest = {
        sourceId: cameraStore.currentCameraId
      }
      if (exceptionsFiltersStore.sortBy) {
        payload.sortBy = exceptionsFiltersStore.sortBy
        payload.sortOrder = exceptionsFiltersStore.sortOrder
      }

      this.isLoading = true
      return api.exceptions.getExceptions(payload)
        .then((res) => {
          this.exceptions = res.data.data.exceptions
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    CREATE_EXCEPTION(data: ExceptionCreationData) {
      return api.exceptions.createException(data)
    },
    DELETE_EXCEPTIONS() {
      return api.exceptions.deleteExceptions(this.selectedExceptions)
    },
    UPDATE_EXCEPTION(id: string, payload: ExceptionUpdateData) {
      return api.exceptions.updateException(id, payload)
    },
    RESET_SELECTED_EXCEPTIONS() {
      this.selectedExceptions = []
    }
  }
})
