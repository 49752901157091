import { defineStore } from 'pinia'
import { RootStatisticsState } from "@/types/store";
import dayjs from "dayjs";
import api from "@/api";

export const useStatisticsStore = defineStore('statistics', {
  state: (): RootStatisticsState => ({
    startDate: dayjs().subtract(1, 'month').startOf('day').toISOString(),
    endDate: dayjs().set('hour', 23).set('minute', 59).toISOString(),
  }),

  actions: {
    GET_EVENTS_STATISTIC() {
      return api.statistics.getEvents({
        from: this.startDate,
        to: this.endDate
      })
    },
    GET_LABELS_STATISTIC() {
      return api.statistics.getLabels({
        from: this.startDate,
        to: this.endDate
      })
    },
    GET_SOURCES_STATISTIC() {
      return api.statistics.getSources()
    },
  }
})
