import { Time } from "@/helpers/time";
import dayjs from "dayjs";
import addLocalUtc from "@/helpers/addLocalUtc";

export default (time: string) => {
  // time type => '12:13:12Z'

  const timeInstance = new Time(time)
  const hours = +timeInstance.hours
  const minutes = +timeInstance.minutes
  const seconds = +timeInstance.seconds
  const now = dayjs()
  const date = now
    .set('h', hours)
    .set('m', minutes)
    .set('s', seconds)

  const localDate = addLocalUtc(date)

  return localDate.format('HH:mm')
}
