import { defineStore } from "pinia";
import { RootCamerasFiltersState } from "@/types/store";
import { OrderEnum } from '@/types/main';

export const useTaskCamerasFiltersStore = defineStore('taskCamerasFilters', {
  state: (): RootCamerasFiltersState => ({
    sortBy: '',
    sortOrder: OrderEnum.desc,
    statusesEks: [],
    groupsIds: [],
    districts: [],
    addresses: [],
  }),

  actions: {
    SET_SORT_BY(sort: string) {
      this.sortBy = sort
    },
    TOGGLE_ORDER() {
      if (this.sortOrder === OrderEnum.desc) {
        this.sortOrder = OrderEnum.asc
      } else {
        this.sortOrder = OrderEnum.desc
      }
    }
  }
})
