import instance from "@/api/http";
import { UserCreationData, UserUpdateData } from "@/types/users";
import { UserResponse, UsersRequest, UsersResponse } from "@/types/api";

export default {
  getUsers(data: UsersRequest) {
    const params = {
      role: data.role,
      sortBy: data.sortBy,
      sortOrder: data.sortOrder,
    }
    return instance.get<UsersResponse>('users', { params })
  },

  getUser(id: string) {
    return instance.get<UserResponse>(`users/${id}`)
  },

  deleteUsers(userIds: Array<string>) {
    const params = { userId: userIds }
    return instance.delete(`users`, { params })
  },

  createUser(data: UserCreationData) {
    return instance.post(`users`, data)
  },

  updateUser(id: string, data: UserUpdateData) {
    return instance.patch(`users/${id}`, data)
  }
}
