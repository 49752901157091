import instance from "@/api/http";
import {
  EventsStatisticResponse,
  LabelsStatisticResponse,
  SourcesStatisticResponse,
  StatisticRequest
} from "@/types/api";

export default {
  getSources() {
    return instance.get<SourcesStatisticResponse>(`stats/source`)
  },
  getLabels(data: StatisticRequest) {
    return instance.get<LabelsStatisticResponse>(`stats/label`, { params: data })
  },
  getEvents(data: StatisticRequest) {
    return instance.get<EventsStatisticResponse>(`stats/event`, { params: data })
  }
}
