import { defineStore } from "pinia";
import { RootUserState } from "@/types/store";
import api from "@/api";

export const useUserStore = defineStore('user', {
  state: (): RootUserState => ({
    isLoading: false,
    currentUser: null
  }),

  actions: {
    GET_USER(id: string) {
      this.isLoading = true
      return api.users.getUser(id)
        .then((res) => {
          const { user } = res.data.data
          this.currentUser = user
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
})
