import { defineStore } from "pinia";
import { RootUsersState } from "@/types/store";
import api from "@/api";
import { UserCreationData, UserUpdateData } from "@/types/users";
import { UsersRequest } from "@/types/api";
import { useUsersFiltersStore } from "@/store/users/usersFilters";

export const useUsersStore = defineStore('users', {
  state: (): RootUsersState => ({
    summaryFilter: '',
    allItemsCount: 0,
    adminItemsCount: 0,
    operatorItemsCount: 0,
    isLoading: false,
    users: [],
    selectedUsers: [],
  }),

  actions: {
    GET_USERS() {
      const usersFiltersStore = useUsersFiltersStore()

      const payload: UsersRequest = {}

      if (this.summaryFilter) {
        payload.role = this.summaryFilter
      }

      if (usersFiltersStore.sortBy) {
        payload.sortBy = usersFiltersStore.sortBy
        payload.sortOrder = usersFiltersStore.sortOrder
      }

      this.isLoading = true
      return api.users.getUsers(payload)
        .then((res) => {
          const { users, count } = res.data.data
          this.users = users
          const { admin, operator } = count
          this.allItemsCount = admin + operator
          this.operatorItemsCount = operator
          this.adminItemsCount = admin
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    CREATE_USER(data: UserCreationData) {
      return api.users.createUser(data)
    },

    UPDATE_USER(id: string, data: UserUpdateData) {
      return api.users.updateUser(id, data)
    },

    DELETE_USERS(ids: Array<string>) {
      return api.users.deleteUsers(ids)
    },

    DELETE_USERS_FROM_SELECTED(usersIds: Array<string>) {
      usersIds.forEach((userId) => {
        this.selectedUsers = this.selectedUsers.filter((i) => i !== userId)
      })
    },

    RESET_SELECTED_USERS() {
      this.selectedUsers = []
    }
  },
})
