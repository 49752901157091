<template>
  <div class="container">
    <div class="access-error">
      Нет доступа к странице
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.access-error {
  padding: 20px 0;
  font-size: 24px;
}
</style>
