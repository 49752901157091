import { defineStore } from "pinia";
import { RootTaskCamerasState } from "@/types/store";
import api from '@/api'
import { CamerasRequest } from "@/types/api";
import { useTaskCamerasFiltersStore } from "@/store/tasks/camerasFilters";
import { useTasksStore } from "@/store/tasks/tasks";

export const useTaskCamerasStore = defineStore('taskCameras', {
  state: (): RootTaskCamerasState => ({
    cameras: [],
    selectedCameras: [],
    isLoading: false,
    currentPage: 1,
    pageSize: 20,
    totalItems: 0
  }),

  actions: {
    GET_CAMERAS() {
      const tasksStore = useTasksStore()
      const taskCamerasFiltersStore = useTaskCamerasFiltersStore()

      const payload: CamerasRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        groupId: taskCamerasFiltersStore.groupsIds,
        district: taskCamerasFiltersStore.districts,
        address: taskCamerasFiltersStore.addresses,
      }
      if (taskCamerasFiltersStore.sortBy) {
        payload.sortBy = taskCamerasFiltersStore.sortBy
        payload.sortOrder = taskCamerasFiltersStore.sortOrder
      }

      this.isLoading = true
      return api.sources.getTaskCameras(tasksStore.currentTaskId, payload)
        .then((res) => {
          const { sources, count } = res.data.data
          this.cameras = sources
          this.totalItems = count
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    DELETE_CAMERAS_FROM_TASK() {
      const tasksStore = useTasksStore()

      return api.tasks.updateTask(tasksStore.currentTaskId, {
        removeSources: this.selectedCameras
      })
    },

    ADD_SOURCES(camerasIds: Array<string>, groupsIds: Array<string>, districtsIds: Array<string>) {
      const tasksStore = useTasksStore()

      return api.tasks.updateTask(tasksStore.currentTaskId, {
        addSources: camerasIds,
        addSourcesGroups: groupsIds,
        addDistricts: districtsIds
      })
    },

    RESET_SELECTED_CAMERAS() {
      this.selectedCameras = []
    }
  },
})
