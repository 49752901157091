import { Nullable } from "@/types/helpers";
import { Group } from "@/types/sources";

export enum UserRole {
  admin = 'admin',
  operator = 'operator'
}

export type UserCreationData = {
  name?: string
  login?: string
  password?: string
  role?: UserRole
  allowedDistricts?: Array<string>
  allowedGroupIds?: Array<string>
  allowedSourceIds?: Array<string>
}

export type UserUpdateData = Omit<UserCreationData, 'role' | 'login'>

export type User = {
  id: string
  login: string
  role: UserRole
  name: string
  allowedDistricts: Nullable<Array<string>>
  allowedGroups: Nullable<Array<Group>>
  allowedSourceIds: Nullable<Array<string>>
  createdBy: Nullable<User>
  createdAt: string
  updatedAt: string
  deletedAt: string
  onlineTime: string
}
