import instance from "@/api/http";
import {
  AddressesResponse,
  CameraResponse, CamerasRequest,
  CamerasResponse, DistrictsResponse, GroupResponse,
  GroupsRequest, GroupsResponse, StreamResponse, TaskCamerasRequest, TaskCamerasResponse
} from '@/types/api';
import { CancelToken } from "axios";

export default {
  getCameras(data?: CamerasRequest, cancelToken?: CancelToken) {
    const params = {
      id: data?.id,
      taskId: data?.taskId,
      limit: data?.limit,
      offset: data?.offset,
      groupId: data?.groupId,
      title: data?.title || null,
      status: data?.status || null,
      statusEks: data?.statusEks,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
      district: data?.district,
      address: data?.address || null,
    }
    return instance.get<CamerasResponse>(`sources`, { params, cancelToken })
  },
  getCamera(id: string) {
    return instance.get<CameraResponse>(`sources/${id}`)
  },
  getTaskCameras(id: string, params: TaskCamerasRequest) {
    return instance.get<TaskCamerasResponse>(`sources/task/${id}`, { params })
  },
  updateCamera() {
    return instance.patch(`cameras`)
  },
  getGroups(data?: GroupsRequest) {
    const params = {
      limit: data?.limit,
      offset: data?.offset,
      title: data?.title || null,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
    }
    return instance.get<GroupsResponse>(`groups`, { params })
  },
  getGroup(id: string) {
    return instance.get<GroupResponse>(`groups/${id}`)
  },
  initStream(id: string) {
    return instance.get<StreamResponse>(`sources/stream/${id}`)
  },
  getDistricts() {
    return instance.get<DistrictsResponse>(`sources/districts`)
  },
  getAddresses(value: string) {
    const params = {
      address: value || null,
      limit: 10
    }
    return instance.get<AddressesResponse>(`sources/addresses`, { params })
  },
  updateCameras() {
    return instance.patch(`sources`)
  },
  getCamerasFile() {
    return instance.get(`sources/file`, { responseType: 'blob' })
  }
}
