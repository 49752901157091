export class Time {
  private time: string;

  minutes: string;

  hours: string;

  seconds: string;

  constructor(time: string) {
    this.time = time
    this.minutes = this.getMinutes()
    this.hours = this.getHours()
    this.seconds = this.getSeconds()
  }

  getMinutes() {
    return this.time.match(/(?<=:)\d{2}/)?.[0] || ''
  }

  getHours() {
    return this.time.match(/\d{2}(?=:)/)?.[0] || ''
  }

  getSeconds() {
    return this.time.match(/\d{2}(?=Z)/)?.[0] || ''
  }
}
