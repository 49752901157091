import { defineStore } from 'pinia'
import { RootProfileState } from '@/types/store';
import { User, UserRole } from '@/types/users';

export const useProfileStore = defineStore('profile', {
  state: (): RootProfileState => ({
    profile: null,
    id: localStorage.getItem('userId') || '',
    role: localStorage.getItem('userRole') as UserRole,
  }),

  actions: {
    SET_USER(user: User) {
      this.id = user.id
      this.role = user.role
      localStorage.setItem('userId', user.id)
      localStorage.setItem('userRole', user.role)
    }
  },

  getters: {
    IS_OPERATOR: (state) => state.role === UserRole.operator,
    IS_ADMIN: (state) => state.role === UserRole.admin,
  }
})
