import { createApp } from 'vue';
import { createPinia } from 'pinia';
import components from '@/plugins/components';
import App from '@/App.vue';
import router from '@/router';
import vClickOutside from '@/directives/clickOutside/index'
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import 'element-plus/dist/index.css'
import '@/styles/main.scss';

const app = createApp(App)

components.forEach((i) => {
  app.component(i.name, i.component)
})

app
  .use(createPinia())
  .use(router)
  .use(ElementPlus, { locale: ru })
  .use(vClickOutside)
  .mount('#app');
