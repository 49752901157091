import {
  createRouter,
  createWebHistory, NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import { useAuthStore } from '@/store/auth';
import UserView from "@/views/users/UserView.vue";
import TasksView from "@/views/tasks/TasksView.vue";
import EventsView from "@/views/EventsView.vue";
import ProfileView from "@/views/ProfileView.vue";
import TaskView from "@/views/tasks/TaskView.vue";
import CameraView from '@/views/sources/CameraView.vue';
import SourcesView from "@/views/sources/SourcesView.vue";
import UsersView from "@/views/users/UsersView.vue";
import StatisticsView from "@/views/StatisticsView.vue";
import AccessErrorView from "@/views/AccessErrorView.vue";
import { UserRole } from '@/types/users';
import { useProfileStore } from '@/store/profile';

const LoginView = () => import('@/views/LoginView.vue');
const NotFoundView = () => import('@/views/NotFoundView.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sources',
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
    meta: {
      loginRequired: true,
      title: 'Страница не найдена'
    }
  },
  {
    path: '/access-error',
    name: 'accessError',
    component: AccessErrorView,
    meta: {
      loginRequired: true,
      title: 'Нет доступа'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'Вход' },
    beforeEnter(to, from, next) {
      const authStore = useAuthStore()

      if (authStore.IS_AUTH) {
        next({ name: 'events' })
      } else {
        next()
      }
    }
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView,
    meta: {
      loginRequired: true,
      title: 'События'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      loginRequired: true,
      title: 'Профиль'
    }
  },
  {
    path: '/sources',
    children: [
      {
        path: '',
        component: SourcesView,
        name: 'sources',
        meta: {
          loginRequired: true,
          title: 'Источники'
        },
      },
      {
        path: '/sources/cameras/:id',
        name: 'camera',
        props: true,
        component: CameraView,
        meta: {
          loginRequired: true,
          title: 'Камера'
        }
      }
    ]
  },
  {
    path: '/users',
    meta: {
      accessRoles: [UserRole.admin],
    },
    children: [
      {
        path: '',
        component: UsersView,
        name: 'users',
        meta: {
          loginRequired: true,
          title: 'Пользователи',
          accessRoles: [UserRole.admin],
        },
      },
      {
        path: '/users/:id',
        name: 'user',
        props: true,
        component: UserView,
        meta: {
          loginRequired: true,
          title: 'Пользователь',
          accessRoles: [UserRole.admin]
        }
      }
    ]
  },
  {
    path: '/tasks',
    children: [
      {
        path: '',
        component: TasksView,
        name: 'tasks',
        meta: {
          loginRequired: true,
          title: 'Задания'
        },
      },
      {
        path: '/tasks/:id',
        name: 'task',
        props: true,
        component: TaskView,
        meta: {
          loginRequired: true,
          title: 'Задание'
        }
      }
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView,
    meta: {
      loginRequired: true,
      title: 'Статистика'
    }
  },
];

function changeTitle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.meta.title) {
    document.title = `IVAR — ${to.meta.title}`
  }
  next()
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'link-active'
});

function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const loginRequired = !!to.matched.find(({ meta }) => meta.loginRequired)
  const authStore = useAuthStore()

  if (!authStore.IS_AUTH && loginRequired) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
}
function accessGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const profileStore = useProfileStore()
  const role = profileStore.role

  if (!role) {
    next()
    return
  }

  const isHaveRights = !!to.matched.find(({ meta }) => {
    if (Array.isArray(meta.accessRoles)) {
      return meta.accessRoles.includes(role)
    }
    return true
  })

  if (isHaveRights) {
    next()
  } else {
    next({ name: 'accessError' })
  }
}

router.beforeEach(accessGuard)
router.beforeEach(authGuard)
router.beforeEach(changeTitle)

export default router;
