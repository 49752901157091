import { defineStore } from "pinia";
import { RootSourcesSelectState } from "@/types/store";
import api from "@/api";

export const useSourcesSelectStore = defineStore('sourcesSelect', {
  state: (): RootSourcesSelectState => ({
    searchValue: '',
    pageSize: 20,

    currentPage: 1,
    totalItems: 0,

    cameras: [],
    allCameraIds: [],
  }),

  actions: {
    GET_CAMERAS() {
      return api.sources.getCameras({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        title: this.searchValue
      })
        .then((res) => {
          this.cameras = res.data.data.sources
          this.totalItems = res.data.data.count["-1"]
        })
    },

    GET_ALL_CAMERA_IDS() {
      return api.sources.getCameras({
        title: this.searchValue
      })
        .then((res) => {
          this.allCameraIds = res.data.data.sources.map((i) => i.id)
        })
    },
  }
})
