export default (weekdaysNumbers: Array<number>) => {
  const weekdaysLabels = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

  if (weekdaysNumbers.length === 7) {
    return 'Ежедневно'
  }

  return weekdaysNumbers
    .map((i) => weekdaysLabels[i - 1])
    .join(', ')
}
