import { defineStore } from "pinia";
import { RootCameraState } from "@/types/store";
import api from "@/api";
import { ImagesRequest } from "@/types/api";
import { useCamerasEventsFiltersStore } from '@/store/sources/eventsFilters';

export const useCameraStore = defineStore('camera', {
  state: (): RootCameraState => ({
    searchValue: '',
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    images: [],
    currentCameraId: '',
    currentCamera: null,
    isLoading: false
  }),

  actions: {
    GET_CAMERA(id: string) {
      this.isLoading = true
      return api.sources.getCamera(id)
        .then((res) => {
          this.currentCamera = res.data.data.source
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    GET_IMAGES() {
      const camerasEventsFiltersStore = useCamerasEventsFiltersStore()

      const payload: ImagesRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        sourceId: this.currentCamera?.id
      }
      if (camerasEventsFiltersStore.sortBy) {
        payload.sortBy = camerasEventsFiltersStore.sortBy
        payload.sortOrder = camerasEventsFiltersStore.sortOrder
      }
      return api.images.getImages(payload)
        .then((res) => {
          this.totalItems = res.data.data.count?.all || 0
          this.images = res.data.data.images
        })
    },

    INIT_STREAM(id: string) {
      return api.sources.initStream(id)
    },
  }
})
