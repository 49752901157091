export default (val: string) => {
  const errors = {
    specialSymbol: false,
    startEnd: false,
    minMaxLength: false,
    letters: false,
  }
  // - Может включать цифры и следующие символы -_.
  if (/^[A-Za-z0-9_.-]+$/g.test(val)) {
    errors.specialSymbol = true
  }
  // - Должен начинаться с буквы, не может заканчиваться точкой
  if (/^[A-Za-z].*[^.]$/g.test(val)) {
    errors.startEnd = true
  }
  // - Должен включать латинские буквы
  if (/(?=.*[A-Za-z])(?!(.*[А-Яа-я]))/g.test(val)) {
    errors.letters = true
  }
  // 6 - 20 символа
  if (val.length >= 6 && val.length <= 20) {
    errors.minMaxLength = true
  }

  return errors
}
