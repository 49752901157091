import { defineStore } from "pinia";
import { RootTaskState } from "@/types/store";
import api from '@/api'
import { IterationsRequest } from '@/types/api';
import { useIterationsFiltersStore } from '@/store/tasks/iterationsFilters';

export const useTaskStore = defineStore('task', {
  state: (): RootTaskState => ({
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    iterations: [],
    currentTask: null,
    isLoading: false
  }),

  actions: {
    GET_TASK(id: string) {
      this.isLoading = true
      return api.tasks.getTask(id)
        .then((res) => {
          this.currentTask = res.data.data.task
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    GET_ITERATION() {
      const iterationsFiltersStore = useIterationsFiltersStore()

      const payload: IterationsRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        taskId: this.currentTask?.id as string,
        status: iterationsFiltersStore.status
      }
      if (iterationsFiltersStore.sortBy) {
        payload.sortBy = iterationsFiltersStore.sortBy
        payload.sortOrder = iterationsFiltersStore.sortOrder
      }
      return api.iterations.getIterations(payload)
        .then((res) => {
          this.iterations = res.data.data.iterations
          this.totalItems = res.data.data.count
        })
    },
  },
})
