import { defineStore } from "pinia";
import { RootCamerasState } from "@/types/store";
import api from "@/api";
import { CamerasRequest } from "@/types/api";
import router from "@/router";
import addQuery from "@/helpers/addQuery";
import { useCamerasFiltersStore } from "@/store/sources/camerasFilters";
import fileLoader from "@/helpers/fileLoader";
import axios, { CancelTokenSource } from "axios";

let previousRequest: CancelTokenSource | null = null;

export const useCamerasStore = defineStore('cameras', {
  state: (): RootCamerasState => ({
    summaryFilter: '',
    searchValue: '',
    currentPage: 1,
    pageSize: 20,
    totalItems: 0,
    allItemsCount: 0,
    workItemsCount: 0,
    snapshotProblemsItemsCount: 0,
    disabledItemsCount: 0,
    clarificationItemsCount: 0,
    deletedItemsCount: 0,
    planItemsCount: 0,
    unavailableItemsCount: 0,
    isLoading: false,
    selectedCameras: [],
    cameras: [],
    allCamerasIds: [],
  }),

  actions: {
    GET_CAMERAS() {
      const camerasFiltersStore = useCamerasFiltersStore()

      const payload: CamerasRequest = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        title: this.searchValue,
        groupId: camerasFiltersStore.groupsIds,
        district: camerasFiltersStore.districts,
        address: camerasFiltersStore.addresses,
      }
      if (this.summaryFilter) {
        payload.statusEks = [this.summaryFilter]
      }
      if (camerasFiltersStore.sortBy) {
        payload.sortBy = camerasFiltersStore.sortBy
        payload.sortOrder = camerasFiltersStore.sortOrder
      }

      this.isLoading = true
      return api.sources.getCameras(payload)
        .then((res) => {
          const { sources, count } = res.data.data
          const { '-1': all, 0: work, 1: problem, 2: disabled, 3: clarification, 4: deleted, 5: plan, 6: unavailable } = count
          this.cameras = sources
          this.totalItems = all
          this.allItemsCount = work + problem + disabled + clarification + deleted + plan + unavailable
          this.workItemsCount = work
          this.snapshotProblemsItemsCount = problem
          this.disabledItemsCount = disabled
          this.clarificationItemsCount = clarification
          this.deletedItemsCount = deleted
          this.planItemsCount = plan
          this.unavailableItemsCount = unavailable
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    GET_ALL_CAMERA_IDS() {
      if (previousRequest) {
        previousRequest.cancel();
      }
      const source = axios.CancelToken.source();
      previousRequest = source;

      const camerasFiltersStore = useCamerasFiltersStore()

      const payload: CamerasRequest = {
        title: this.searchValue,
        groupId: camerasFiltersStore.groupsIds,
        district: camerasFiltersStore.districts
      }
      if (this.summaryFilter) {
        payload.statusEks = [this.summaryFilter]
      }
      if (camerasFiltersStore.sortBy) {
        payload.sortBy = camerasFiltersStore.sortBy
        payload.sortOrder = camerasFiltersStore.sortOrder
      }

      return api.sources.getCameras(payload, source.token)
        .then((res) => {
          this.allCamerasIds = res.data.data.sources.map((i) => i.id)
        })
        .catch(() => {})
    },

    UPDATE_CAMERAS() {
      return api.sources.updateCameras()
    },

    RESET_SELECTED_CAMERAS() {
      this.selectedCameras = []
    },

    INIT_PAGINATION() {
      this.currentPage = Number(router.currentRoute.value.query.page) || 1
      return addQuery('page', String(this.currentPage))
        .then(this.GET_CAMERAS)
    },

    GET_SOURCES_FILE() {
      return api.sources.getCamerasFile()
        .then((res) => {
          fileLoader(res.data, 'sources', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    }
  }
})
