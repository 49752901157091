<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="header__body">
          <div class="header__title">
            <slot name="title"/>
          </div>
          <div class="header__search">
            <slot name="search"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.header {
  border-bottom: 1px solid #26292F;
  width: 100%;
  padding: 8px 20px;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 800;
  }

  &__search {
    width: 260px;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.content {
  padding: 12px 0 10px;
}
</style>

<style lang="scss">

@media (max-width: 768px) {
  .container {
    min-width: 320px;
    width: 100%;
    padding: 0 10px;
  }
}
</style>
