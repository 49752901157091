import { Group } from "@/types/sources";

export default (groups: Array<Group> | null | undefined) => {
  if (!groups) {
    return ''
  }
  return groups
    .map((i) => i.title)
    .join(', ')
}
