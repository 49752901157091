<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_526_155668)">
      <path d="M15.3334 4.66699L10.6667 8.00033L15.3334 11.3337V4.66699Z" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.33342 3.33301H2.00008C1.2637 3.33301 0.666748 3.92996 0.666748 4.66634V11.333C0.666748 12.0694 1.2637 12.6663 2.00008 12.6663H9.33342C10.0698 12.6663 10.6667 12.0694 10.6667 11.333V4.66634C10.6667 3.92996 10.0698 3.33301 9.33342 3.33301Z" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_526_155668">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
