export type DaysWeekType = Array<number>

export type TimeType = `${number | 0}${number}:${number | 0}${number}`

export type ElTableType = {
  scrollTo:(options: ScrollToOptions) => void
}

export enum OrderEnum {
  asc = 'asc',
  desc = 'desc'
}
