import { tasksCategories } from "@/consts/tasksConsts";
import { TaskCategory } from "@/types/tasks";

export default (categories: Array<TaskCategory>) => {
  return categories
    .map((i) => {
      return tasksCategories[i]
    })
    .join(', ')
}
