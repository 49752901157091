import { Iteration, TaskCategory } from "@/types/tasks";
import { Camera } from "@/types/sources";
import { Nullable } from "@/types/helpers";
import { User } from "@/types/users";

export enum DecisionEnum {
  accepted = 'accepted',
  correction = 'correction',
  rejected = 'rejected',
  other_label = 'other_label',
  without_decision = 'without_decision'
}

export type EventType = {
  id: string
  standardImage: Nullable<string>
  label: TaskCategory
  confidence: number
  shift: Nullable<number>
  zoom: Nullable<number>
  createdAt: string
  updatedAt: string
  deletedAt: Nullable<string>
}

export type Image = {
  id: string
  iteration: Iteration
  source: Camera
  decision: DecisionEnum
  processedAt: string
  createdAt: string
  updatedAt: string
  link: string
  deletedAt: Nullable<string>
  events: Array<EventType>
  ticketLabel: Nullable<TaskCategory>
  operator: Nullable<User>
  ticketId: number
  decisionTime: Nullable<string>
}

export enum EventsTabEnum {
  all = 'all',
  processed = 'processed',
  unprocessed = 'unprocessed',
}
